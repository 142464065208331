<template>
   <div class="app-main__inner dm-sans-font">
        <div class="flex justify-between align-items-center mb-4">
            <div class="text-sm md:text-xl font-semibold text-gray-900">Integration</div>
        </div>

        <div class="container mt-3">
            <div class="w-full mb-3 p-2 rounded">
                <div class="font-semibold text-base mb-2">Integreta de programm där ni har er produktkatalog med era leverantörer för att effektivt Hantera
                    lager och inköpsordrar.
                </div>
                <span>Anslut appar enkelt nedam för att matcha och synka ert lagersaldo med leverantörens.</span>
            </div>
            <div class="row mb-3">
                <div class="col-md-3" v-for="(provider,key) in GET_WEBSHOP_PROVIDER" :key="key">
                    <div class="flex justify-center bg-white rounded-md shadow-sm p-3">
                        <span>
                            <router-link class="no-deco" :to="{ name : 'addshopify' }" v-if="provider.name.toLowerCase().includes('shopify')">
                                <span class="flex justify-center h-16 w-full flex">
                                    <img class="img-fluid" :src="provider.image" alt="">
                                </span>
                                <!-- {{ provider.name }} -->
                            </router-link>
                            <router-link class="no-deco" :to="{ name : 'addwoo' }" v-if="provider.name.toLowerCase().includes('woocommerce')">
                                <span class="flex justify-center h-16 w-full flex">
                                    <img class="img-fluid" :src="provider.image" alt="">
                                </span>
                                <!-- {{ provider.name }} -->
                            </router-link>
                            <router-link class="no-deco" :to="{ name : 'addfortnox' }" v-if="provider.name.toLowerCase().includes('fortnox')">
                                <span class="flex justify-center h-16 w-full flex">
                                    <img class="img-fluid" :src="provider.image" alt="" v-if="provider.image">
                                    <span v-else class="font-semibold">{{ provider.name }}</span>
                                </span>
                            </router-link>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row bg-white p-4">
                <div class="col-md-8 flex flex-col">
                    <span class="text-base text-gray-800">Connected Sources</span>
                    <div class="w-full">
                        <div class="table-responsive">
                            <table style="width: 100%;" id="exampledestroy" class="table">
                                <thead class="hidden">
                                    <tr class="bg-white rounded p-1 border-b-2 shadow-sm">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody v-for="connected in GET_CONNECTED_WEBSHOP.data" :key="connected.id">
                                    <tr>
                                        <td>{{ getProviderName(connected.provider_id) }}</td>
                                        <td>{{ connected.shop_name }}</td>
                                        <td>
                                            <span class="px-4 py-1 bg-green-500 text-white rounded-sm" v-if="connected.is_active">Active</span>
                                            <span class="px-4 py-1 bg-red-500 text-white rounded-sm" v-else>Inactive</span>
                                        </td>
                                        <td>
                                            <div class="flex justify-center space-x-2">
                                                <span v-if="connected.is_active" class="flex justify-center align-items-center h-7 w-7 rounded-full bg-green-500 cursor-pointer">
                                                    <img src="./../../assets/images/icons/correct.png" alt="">
                                                </span>
                                                <span @click="updateWebshop(connected)" class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                <img src="./../../assets/images/icons/edit.png" alt="">
                                                </span>
                                                <span @click="removeWebshop(connected)" class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                    <img src="./../../assets/images/icons/delete.png" alt="">
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- <tr v-for="connected in GET_CONNECTED_WEBSHOP.data" :key="connected.id">
                                        <td>{{ getProviderName(connected.provider_id) }}</td>
                                        <td>{{ connected.shop_name }}</td>
                                        <td>
                                            <span class="px-3 bg-green-400 rounded" v-if="connected.is_active">active</span>
                                            <span class="px-3 bg-gray-400 rounded" v-else>inactive</span>
                                        </td>
                                        <td>
                                            <div class="flex align-items-center space-x-3">
                                                <span class="flex align-items-center -mt-1">
                                                    <input v-if="connected.is_active" type="checkbox" disabled class="font-xs checkbox-integration text-green-500" checked>
                                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                                <span class="cursor-pointer" @click="updateWebshop(connected)"><i class="metismenu-icon iconly-brokenEdit"></i></span>
                                                <span class="cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card flex flex-col p-3 justify-between md:mx-4 card-side-4 h-full">
                        <div class="flex flex-col text-white">
                            <span>{{ $t('totalProducts') }}</span>
                            <span class="text-white text-2xl font-semibold" id="total-product">{{ $services.helpers.animateValue('total-product', 0, GET_INTEGRATION_METRICS.total_matched_products || 0, 4000) }}</span>
                            <hr class="border-gray-700 mt-2">
                        </div>
                        <div class="flex flex-col text-white">
                            <span>{{ $t('totalConnectedSuppliers') }}</span>
                            <span class="text-white text-2xl font-semibold" id='total-connected'>{{ $services.helpers.animateValue('total-connected', 0, GET_INTEGRATION_METRICS.total_connected_suppliers || 0, 4000) }}</span>
                            <hr class="border-gray-700 mt-2">
                        </div>
                        <div class="flex flex-col text-white">
                            <span>{{ $t('totalOrderAmountMonth') }}</span>
                            <span class="text-white text-2xl font-semibold" id="total-order-amount">{{ $services.helpers.animateValue('total-order-amount', 0, GET_INTEGRATION_METRICS.total_order_amount_from_all_conneceted_shops || 0, 4000) }}</span>
                            <hr class="border-gray-700 mt-2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { v4 as uuidv4 } from 'uuid'

    // import debounce from 'lodash.debounce'
    // import Paginate from 'vuejs-paginate'

    export default {
        name : 'WebshopOverview',
        components : {
            // Notification : () => import('./../../customComponent/NotificationAlert'),
            // paginate : Paginate,
        },
        data () {
            return {
                loading : true
            }
        },
        computed : {
            ...mapGetters({
                GET_WEBSHOP_PROVIDER : 'customer/GET_WEBSHOP_PROVIDER',
                GET_INTEGRATION_METRICS : 'customer/GET_INTEGRATION_METRICS',
                GET_CONNECTED_WEBSHOP : 'customer/GET_CONNECTED_WEBSHOP'
            })
        },
        mounted () {
            const self = this
            this.getIntegrationMetrics()
            this.getProvider()
            this.getConnectedWebshop()
            // console.log(uuidv4())

            window.Bus.$on('get-connected-integration', _ => {
                self.getConnectedWebshop()
            })
        },
        methods : {
            hideDropdown () {
                // this.showProductDropdown = false
                // this.showSupplierDropdown = false
                this.showWebshopDropdown = false
            },
            openAddWebshopModal () {
                window.Bus.$emit('open-add-webshop-modal')
            },
            randomUUID (id) {
                return uuidv4() + `-${id}` + Math.random().toString(36).substring(2, 5)
            },
            getIntegrationMetrics () {
                this.$store.dispatch('customer/getIntegrationMetric')
            },
            updateWebshop (data) {
                if (parseInt(data.provider_id) === 1) {
                    window.localStorage.setItem('__shop__d', data.id)
                    this.$router.push({ name : 'addshopify', query : { name : 'shopify' } })
                }
                if (parseInt(data.provider_id) === 2) {
                    window.localStorage.setItem('__woo__d', data.id)
                    this.$router.push({ name : 'addwoo', query : { name : 'woocommerce' } })
                }
                if (parseInt(data.provider_id) === 3) {
                    this.$router.push({ name : 'addfortnox', query : { integration : data.id } })
                }
            },
            removeWebshop (data) {
                window.Bus.$emit('remove-integration', data)
            },
            getConnectedWebshop () {
                this.$store.dispatch('customer/getConnectedWebshop', {data: `?is_connected=True`})
            },
            getProviderName (provider) {
                const providerName = this.GET_WEBSHOP_PROVIDER.filter(item => parseInt(item.id) === parseInt(provider))
                if (providerName.length > 0) {
                    return providerName[0].name
                }
                return 'Not Available'
            },
            getProvider () {
                this.$store.dispatch('customer/getIntegrationProvider')
                .then(_ => { this.loading = false })
                .catch(_ => { this.loading = false })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import "./../../assets/variables.scss";
.app-theme-gray .main-card > .card-body > .card-title::before{
    background: unset !important;
}
// .table td{
//     padding: 0.2rem 0.5rem !important;
// }
.bold-text{
    font-family: 'Roboto';
    font-weight: 700;
}
.border-box{
    border: 2px solid #17B4E5 !important;
}
.card-text-col {
    color: #17B4E5 !important;
}
.bg-blue-cus{
    background: #17B4E5;
}
.border-box-2{
    border: 1px solid #35D990 !important;
}
.card-text-col-2{
    color: #35D990;
}
.card-side-4{
    background: $production-theme;
}
</style>
